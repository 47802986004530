import { ChangeEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Collapse from '@material-ui/core/Collapse'
import SettingIcon from '@/assets/images/setting.svg'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { SFSwitch, SFText } from '@otsofintech/sofinx-ui'
import ChartColorIcon from '@/components/svg/chart-color-icon'
import * as Styled from './index.style'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { setBrowserNotify, setDarkMode, setChartColor } from '@/store/actions'

const SettingMenu = () => {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()
  const setting = useSelector((state: RootState) => state.setting)

  // menu open
  const [isOpen, setIsOpen] = useState(false)
  const handleClickAway = () => setIsOpen(false)
  const handleClick = () => setIsOpen(prev => !prev)

  // setting
  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    if (target.name === 'dark') dispatch(setDarkMode(target.checked))
    else if (target.name === 'notify') dispatch(setBrowserNotify(target.checked))
  }

  const handleChartColor = (data: 'greenUp' | 'redUp') => {
    dispatch(setChartColor(data))
  }

  const settingList = useMemo(() => {
    return [
      { key: 'dark', label: 'darkMode', content: <SFSwitch name="dark" checked={setting.darkMode} onChange={handleSwitch} /> },
      // { key: 'notify', label: 'receiveBrowerNotify', content: <SFSwitch name="notify" checked={setting.browserNotify} onChange={handleSwitch} /> },
      { key: 'color', label: 'upAndDownColor', content: <SFText mx="16px" level={2} fontWeight="700" color="grey40">{t(setting.chartColor)}</SFText> },
    ].map(item =>
      <Styled.SettingItem key={item.key}>
        <SFText level={2} color="grey40">{ t(item.label) }</SFText>
        { item.content }
      </Styled.SettingItem>
    )
  }, [setting, i18n.language])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Styled.Container>
        <Styled.SettingButton
          m="auto"
          src={SettingIcon}
          color={ isOpen ? 'primary' : 'grey50' }
          onClick={handleClick}
          pointer
        />
        <Styled.Content>
          <Collapse in={isOpen}>
            <Styled.MenuContent>
              { settingList }
              <Styled.ChartColorContent>
                <ChartColorIcon up="primary" down="error2" active={setting.chartColor === 'greenUp'} onClick={() => handleChartColor('greenUp')} style={{ marginLeft: '8px', cursor: 'pointer' }} />
                <ChartColorIcon up="error2" down="primary" active={setting.chartColor === 'redUp'} onClick={() => handleChartColor('redUp')} style={{ marginLeft: '8px', cursor: 'pointer' }} />
              </Styled.ChartColorContent>
            </Styled.MenuContent>
          </Collapse>
        </Styled.Content>
      </Styled.Container>
    </ClickAwayListener>
  )
}

export default SettingMenu
