import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { SFSVG, SFIconImage } from '@otsofintech/sofinx-ui'

export const AccountSVG = styled(SFSVG)``

export const Container = styled.div`
  position: relative;
  display: flex;

  &:hover > .menu {
    /* height: 272px; // 每個選項增加48px */
    height: fit-content;
  }

  & > .menu.close {
    height: 0 !important;
  }

  &:hover ${ AccountSVG } {
    fill: ${ props => props.theme.palette.primary };
  }

  &:hover:before {
    content: '';
    position: absolute;
    top: 20px;
    right: 0;
    width: 248px;
    height: 32px;
    opacity: 0;
  }
`

export const Box = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`

export const MenuContent = styled.div`
  position: absolute;
  top: 52px;
  right: 0;
  width: 264px;
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${ props => props.theme.shadow[600] };
  background-color: ${ props => props.theme.palette.blank };
  transition: all .2s;
`

export const UserHeader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  &:hover {
    background-color: ${ props => props.theme.palette.grey10 };
  }
`

export const NavList = styled.section`
  padding: 16px;
  border-top: 1px solid ${ props => props.theme.palette.grey20 };
  border-bottom: 1px solid ${ props => props.theme.palette.grey20 };
`

export const NavItem = styled(NavLink)`
  padding: 8px;
  display: flex;
  color: ${ props => props.theme.palette.grey40 };
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    background: ${ props => props.theme.palette.primary }1A;
    color: ${ props => props.theme.palette.grey50 };
  }

  // active
  &.active-menu{
    background: ${ props => props.theme.palette.primary }1A;
    color: ${ props => props.theme.palette.grey50 };
  }

  &.active-menu path {
    fill: ${ props => props.theme.palette.primary };
  }
`
export const CircleDiv = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Circle = styled(SFIconImage)`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  fill: ${ props => props.theme.palette.grey50 };
  cursor: pointer;
  transition: border .2s;
  :hover{
    fill: ${ props => props.theme.palette.primary };
  }
`

export const IconSVG = styled(SFSVG)`
  margin-right: 16px;
`

export const Logout = styled.div`
  margin: 16px;
  padding: 8px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${ props => props.theme.palette.warn2 }33;

    path {
      fill: ${ props => props.theme.palette.warn2 }
    }
  }
`
