import { ReactNode } from 'react'
import { SFSVG } from '@otsofintech/sofinx-ui'
import { deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import CheckIcon from '@/assets/images/check-done.svg'
import RewardIcon from '@/assets/images/reward.svg'
import WarnIcon from '@/assets/images/error-outline.svg'
import ErrorIcon from '@/assets/images/error-fill.svg'
import ErrorOutlineIcon from '@/assets/images/error-outline.svg'
import CloseIcon from '@/assets/images/close-outline.svg'
import SignalStop from '@/assets/images/signal-stop.svg'
import ArrowCircleIcon from '@/assets/images/arrow-circle.svg'
import { isSofinqDomain } from '@/utils/helper'

type Detail = {
  icon: ReactNode
  title: string
  category: string
  message: string
  link: string
}

type MessageTypes = {
  [props: string]: (data: any) => Detail
}

export const getMessageDetail = (value: Common.NotifyPayload) => {
  const newValue = deepCopy(value)
  const data = deepCopy(newValue.data)

  if (newValue.type === 'RobotTrade') {
    newValue.type = `${newValue.type}${data.side === 0 ? 'Buy' : 'Sell'}`
  }

  const detail =  messageTypes[newValue.type] ? messageTypes[newValue.type](data) : {} as any

  return {
    id: newValue.id || String(Math.random()),
    type: newValue.type || '',
    time: newValue.time || '',
    data: data || {},
    category: detail.category || 'other',
    icon: detail.icon || null,
    title: detail.title || '',
    message: detail.message || '',
    link: detail.link || '',
  }
}

const messageTypes: MessageTypes = {
  // Reward
  RewardRegistered: (data: any) => ({
    icon: () => <SFSVG src={RewardIcon} color="warn2" />,
    title: 'rewardNotifyTitle',
    category: 'reward',
    message: 'rewardRegistered',
    link: '/user-assets'
  }),
  RewardFirstCompleteRobotCreate: (data: any) => ({
    icon: () => <SFSVG src={RewardIcon} color="warn2" />,
    title: 'rewardNotifyTitle',
    category: 'reward',
    message: 'rewardFirstCompleteRobotCreate',
    link: '/user-assets'
  }),
  RewardFirstStrategyRobotCreate: (data: any) => ({
    icon: () => <SFSVG src={RewardIcon} color="warn2" />,
    title: 'rewardNotifyTitle',
    category: 'reward',
    message: 'rewardFirstStrategyRobotCreate',
    link: '/user-assets'
  }),
  RewardIntroduce: (data: any) => ({
    icon: () => <SFSVG src={RewardIcon} color="warn2" />,
    title: 'rewardNotifyTitle',
    category: 'reward',
    message: 'rewardIntroduce',
    link: '/user-assets'
  }),
  // Robot
  RobotMarginCall: (data: any) => ({
    icon: () => <SFSVG src={WarnIcon} color="warn2" />,
    title: 'marginWarnNotifyTitle',
    category: 'robot',
    message: 'robotMarginCall',
    link: `/robot/${data.id}`,
  }),
  RobotMarginStopout: (data: any) => ({
    icon: () => <SFSVG src={ErrorIcon} color="error2" />,
    title: 'marginLackNotifyTitle',
    category: 'robot',
    message: 'robotMarginStopout',
    link: `/robot/${data.id}`,
  }),
  RobotPositionClosedByBroker: (data: any) => ({
    icon: () => <SFSVG src={CloseIcon} color="error2" />,
    title: 'robotPositionClosedByBrokerTitle',
    category: 'robot',
    message: 'robotPositionClosedByBroker',
    link: `/robot/${data.id}`,
  }),
  RobotStop: (data: any) => ({
    icon: () => <SFSVG src={ErrorOutlineIcon} color={data.reason === 'TAKE_PROFIT' ? 'down' : data.reason === 'STOP_LOSS' ? 'up' : 'warn2' } />,
    title: 'stopTradeRobot',
    category: 'robot',
    message: data.reason === 'TAKE_PROFIT' ? 'robotStopTakeProfit' : 
      data.reason === 'STOP_LOSS' ? 'robotStopStopLoss' : 'stopRobotCompetition' ,
    link: `/robot/${data.id}`,
  }),
  RobotTradeBuy: (data: any) => ({
    icon: () => <SFSVG style={{ transform: 'rotate(180deg)' }} src={ArrowCircleIcon} color="up" />,
    title: 'tradeBuyNotifyTitle',
    category: 'robot',
    message: 'robotTrade',
    link: `/robot/${data.id}`,
  }),
  RobotTradeSell: (data: any) => ({
    icon: () => <SFSVG src={ArrowCircleIcon} color="down" />,
    title: 'tradeSellNotifyTitle',
    category: 'robot',
    message: 'robotTrade',
    link: `/robot/${data.id}`,
  }),
  RobotCreationDisabled: (data: any) => ({
    icon: () => <SFSVG src={ArrowCircleIcon} color="down" />,
    title: 'robotCreationDisabledTitle',
    category: 'robot',
    message: 'robotCreationDisabled',
    link: `/robot/${data.id}`,
  }),

  // Deposit
  DepositSuccess: (data: any) => ({
    icon: () => <SFSVG src={CheckIcon} color="primary" />,
    title: isSofinqDomain() ? 'paymentSuccessNotifyTitle' : 'depositSuccessNotifyTitle',
    category: 'deposit',
    message: isSofinqDomain() ? 'paymentSuccess' : 'depositSuccess',
    link: '/user-assets',
  }),
  DepositFailed: (data: any) => ({
    icon: () => <SFSVG src={ErrorIcon} color="error2" />,
    title: isSofinqDomain() ? 'paymentFailNotifyTitle' : 'depositFailNotifyTitle',
    category: 'deposit',
    message: isSofinqDomain() ? 'paymentFailed' : 'depositFailed',
    link: '/user-assets',
  }),
  DepositByBroker: (data: any) => ({
    icon: () => <SFSVG src={CheckIcon} color="primary" />,
    title: isSofinqDomain() ? 'paymentSuccessNotifyTitle' : 'depositSuccessNotifyTitle',
    category: 'deposit',
    message: 'depositByBroker',
    link: '/user-assets',
  }),
  DepositDemoBalanceSuccess: (data: any) => ({
    icon: () => <SFSVG src={CheckIcon} color="primary" />,
    title: isSofinqDomain() ? 'paymentSuccessNotifyTitle' : 'depositSuccessNotifyTitle',
    category: 'deposit',
    message: 'depositDemoBalanceSuccess',
    link: '/user-assets',
  }),
  DepositDemoBalanceFailed: (data: any) => ({
    icon: () => <SFSVG src={ErrorIcon} color="error2" />,
    title: isSofinqDomain() ? 'paymentFailNotifyTitle' : 'depositFailNotifyTitle',
    category: 'deposit',
    message: 'depositFailed',
    link: '/user-assets',
  }),
  DepositDemoBalanceByBroker: (data: any) => ({
    icon: () => <SFSVG src={CheckIcon} color="primary" />,
    title: isSofinqDomain() ? 'paymentSuccessNotifyTitle' : 'depositSuccessNotifyTitle',
    category: 'deposit',
    message: 'depositDemoBalanceByBroker',
    link: '/user-assets',
  }),

  // Signal
  SignalStop: (data: any) => ({
    icon: () => <SFSVG src={SignalStop} color="error2" />,
    title: 'signalStopNotifyTitle',
    category: 'signal',
    message: 'signalStop',
    link: `/signal/${data.id}`,
  }),

  // Withdraw
  WithdrawSuccess: (data: any) => ({
    icon: () => <SFSVG src={CheckIcon} color="primary" />,
    title: 'withdrawSuccessNotifyTitle',
    category: 'withdraw',
    message: 'withdrawSuccess',
    link: '/user-assets',
  }),
  WithdrawFailed: (data: any) => ({
    icon: () => <SFSVG src={ErrorIcon} color="error2" />,
    title: 'withdrawFailNotifyTitle',
    category: 'withdraw',
    message: 'withdrawFailed',
    link: '/user-assets',
  }),
  WithdrawByBroker: () => ({
    icon: () => <SFSVG src={ErrorIcon} color="primary" />,
    title: 'withdrawSuccessNotifyTitle',
    category: 'withdraw',
    message: 'withdrawByBroker',
    link: '/user-assets',
  }),

  // Competition
  CompetitionPublished: (data: any) => ({
    icon: () => <SFSVG src={WarnIcon} color="warn2" />,
    title: 'competitionPublishNoticeTitle',
    category: 'competition',
    message: 'competitionPublishNotice',
    link: isSofinqDomain()? `https://sofinq.com/competition-explore/${data.id}`:`/competition-explore/${data.id}`,
  }),
  CompetitionStarted: (data: any) => ({
    icon: () => <SFSVG src={WarnIcon} color="warn2" />,
    title: 'competitionStartNotifyTitle',
    category: 'competition',
    message: 'competitionStartNotice',
    link: isSofinqDomain()? `https://sofinq.com/competition-explore/${data.id}`:`/competition-explore/${data.id}`,
  }),
  CompetitionWillEndSoon: (data: any) => ({
    icon: () => <SFSVG src={WarnIcon} color="warn2" />,
    title: 'competitionWillEndSoonNoticeTitle',
    category: 'competition',
    message: 'competitionWillEndSoonNotice',
    link: isSofinqDomain()? `https://sofinq.com/competition-explore/${data.id}`:`/competition-explore/${data.id}`,
  }),
  CompetitionEnded: (data: any) => ({
    icon: () => <SFSVG src={WarnIcon} color="warn2" />,
    title: 'competitionEndNotifyTitle',
    category: 'competition',
    message: 'competitionEndNotice',
    link: isSofinqDomain()? `https://sofinq.com/competition-explore/${data.id}`:`/competition-explore/${data.id}`,
  }),
  CompetitionTerminated: (data: any) => ({
    icon: () => <SFSVG src={WarnIcon} color="warn2" />,
    title: 'competitionTerminateNoticeTitle',
    category: 'competition',
    message: 'competitionTerminateNotice',
    link: isSofinqDomain()? `https://sofinq.com/competition-explore/${data.id}`:`/competition-explore/${data.id}`,
  }),

  // Order
  TradeFailed: (data: any) => ({
    icon: () => <SFSVG src={WarnIcon} color="error2" />,
    title: 'notEnoughFreeMarginTitle',
    category: 'order',
    message: data.reason === 'NOT_ENOUGH_FREE_MARGIN' ? 'notEnoughFreeMarginMsg' : '',
    link: `/robot/${data.robotId}`,
  }),
}
