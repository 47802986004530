import { useEffect, useMemo, useRef, useState, MouseEvent, memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { SFText, SFAutoEllipsis } from '@otsofintech/sofinx-ui'
import { toCamel, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { GetNotifyListService, PutNotifyService } from '@/services/api-helper/notify'
import { UserAuth } from '@/utils/enum'
import { timeFormat, toTimeStamp } from '@/utils/dataFormator'
import useWebSocket from '@/hooks/useWebSocket'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ArrowRightIcon from '@/assets/images/arrow-right.svg'
import EmptyMessageIcon from '@/assets/images/freeze-color/empty-message.svg'
import ExpandIcon from '@/assets/images/expand.svg'
import { getMessageDetail } from './typeMap'
import timerQueue from './timer-queue'
import Message from './message'
import Notify from './notify'
import NewNotify from './new-notify'
import { useNotifyTest } from './test'
import * as Styled from './index.style'
import { isSofinqDomain } from '@/utils/helper'

interface NotifySystemProps {
  isOpen: boolean
  handleOpen: () => void
  handleClose: () => void
  onHaveMessage: (value: boolean) => void
}

type MessageType = {
  icon: ReactNode
  title: string
  category: string
  message: string
}

type NotifyDetail = MessageType & Common.NotifyPayload

const typeList = ['reward', 'robot', 'deposit', 'signal', 'withdraw', 'competition', 'order'] as const

type NotifyTypes = typeof typeList[number]

function getTypeData (initData: any) {
  return {
    reward: deepCopy(initData),
    robot: deepCopy(initData),
    deposit: deepCopy(initData),
    signal: deepCopy(initData),
    withdraw: deepCopy(initData),
    competition: deepCopy(initData),
    order: deepCopy(initData),
  }
}

let closing = false

const queue = timerQueue()

const NotifySystem = ({
  isOpen,
  handleOpen,
  handleClose,
  onHaveMessage,
}: NotifySystemProps) => {
  const { t } = useTranslation()

  const history = useHistory()

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)

  const brokerName = useSelector((state: RootState) => state.companyInfo.name)

  // 登入時取得舊訊息
  useEffect(() => {
    if (isLogin) getAllNotify()
    else setAllNotify(getTypeData([]))

    async function getAllNotify () {
      const result =  await GetNotifyListService()
      if (result.isError || result.value.length === 0) return

      const notifyList = result.value.sort((a, b) => toTimeStamp(a.time) - toTimeStamp(b.time))
      setAllNotify(prev => {
        const value = { ...prev }
        notifyList.forEach(item => {
          const detail = getMessageDetail(item)
          value[detail.category as NotifyTypes]?.push(detail)
        })
        return value
      })
    }
  }, [isLogin])

  // 所有訊息
  const [allNotify, setAllNotify] = useState<Record<NotifyTypes, NotifyDetail[]>>(getTypeData([]))

  // 新訊息
  const [newNotifyList, setNewNotifyList] = useState<NotifyDetail[]>([])
  useEffect(() => {
    queue.createInteval((data: any) => { setNewNotifyList(prev => [...prev, data]) })
    return () => queue.clearIntervel()
  }, [])

  useWebSocket({
    channel: 'Notify',
    channelString: 'notify#Subscribe@Notify',
    unsubscribeString: 'notify#Unsubscribe@Notify',
    needAuth: true,
    callback: (rawValue: any) => {
      const payload = rawValue.payload as Common.NotifyPayload
      payload.data = toCamel(JSON.parse(payload.data as unknown as string))
      const detail = getMessageDetail(payload)
      queue.push(detail)
    },
  }, [])

  const haveMessage = useMemo(() => {
    return newNotifyList.length > 0 || typeList.some(type => allNotify[type].length)
  }, [newNotifyList, allNotify])

  useEffect(() => {
    onHaveMessage(haveMessage)
  }, [haveMessage])

  // 依時間排序類型
  const sortTypeList = useMemo(() => {
    const list: NotifyTypes[] = typeList.slice()

    return list.sort((a, b) => {
      const aTime = toTimeStamp(allNotify[a][allNotify[a].length - 1]?.time)
      const bTime = toTimeStamp(allNotify[b][allNotify[b].length - 1]?.time)
      return bTime - aTime
    })
  }, [allNotify, newNotifyList])

  useEffect(() => {
    setIsTypeOpen(getTypeData(false))
    // 整理訊息
    if (newNotifyList.length) {
      setAllNotify(prev => {
        const value = { ...prev }
        newNotifyList.forEach(item => value[item.category as NotifyTypes].push(item))
        return value
      })

      setNewNotifyList([])
    }
  }, [isOpen])

  // 置頂查看新訊息
  const contentNode = useRef<HTMLDivElement>(null)
  const backTopNode = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (contentNode.current === null) return
    if (contentNode.current.scrollTop > 50) return

    backTopNode.current?.classList.add('close')
    setTimeout(() => {
      backTopNode.current?.classList.remove('appear')
      backTopNode.current?.classList.remove('close')
    }, 500)
  }

  useEffect(() => {
    if (isOpen === false) {
      contentNode.current?.scrollTo({top: 0})
      backTopNode.current?.classList.remove('appear')
      backTopNode.current?.classList.remove('close')
      return
    }

    if (newNotifyList.length === 0) return
    if (contentNode.current === null) return
    if (contentNode.current?.scrollTop < 100) return

    backTopNode.current?.classList.add('appear')
  }, [newNotifyList, isOpen])

  const handleToTop = () => {
    contentNode.current?.scrollTo({top: 0, behavior: 'smooth'})
  }

  // 全部清除
  const handleClearAll = () => {
    PutNotifyService({ id: [] })
    contentNode.current?.classList.add('close')

    // 執行動畫
    setTimeout(() => {
      setAllNotify(getTypeData([]))
      setNewNotifyList([])
    }, 200)

    // 等待執行完副作用再移除關閉動畫
    setTimeout(() => {
      contentNode.current?.classList.remove('close')
    }, 400)
  }

  // 刪除訊息
  const handleDelete = async (event: MouseEvent, type: NotifyTypes, index: number, id: string) => {
    event.stopPropagation()

    // 刪除群組訊息
    if (isTypeOpen[type] === false) {
      const body = { id: allNotify[type].map(item => item.id) }
      PutNotifyService(body)

      setAllNotify(prev => ({ ...prev, [type]: [] }))
    }

    // 刪除單一訊息
    if (isTypeOpen[type] && closing === false) {
      closing = true
      typesElement.current[type]?.querySelector(`[id="${id}"]`)?.classList.add('fade')

      PutNotifyService({ id: [ id ] })

      setTimeout(() => {
        setAllNotify(prev => {
          const result = { ...prev }
          result[type].splice(result[type].findIndex(item => item.id === id), 1)
          return result
        })

        closing = false
      }, 200)
    }
  }

  // 連結
  const handleLink = (type: NotifyTypes, link: string) => {
    if (isTypeOpen[type] && link) {
      isSofinqDomain() ? window.location.replace(link) : history.push(link)
    }
  }

  // 展開/收合
  const [ isTypeOpen, setIsTypeOpen ] = useState<Record<NotifyTypes, boolean>>(getTypeData(false))

  const handleTypeOpen = (event: MouseEvent<HTMLElement>, type: NotifyTypes, value: boolean) => {
    event.stopPropagation()

    if (isTypeOpen[type] === value) return
    setIsTypeOpen(prev => {
      const result = { ...prev }
      result[type] = value
      return result
    })
  }

  // 無訊息時 收合&關閉高度
  useEffect(() => {
    typeList.forEach(type => {
      allNotify[type].length === 0 && setIsTypeOpen(prev => ({ ...prev, [type]: false }))
    })
  }, [allNotify])

  // 卡片顯示數量
  const currMapList = useMemo(() => {
    const result = {} as Record<NotifyTypes, any[]>

    typeList.forEach(item => {
      result[item] = isTypeOpen[item] ? allNotify[item] : allNotify[item].slice(-3)
    })
    return result
  }, [isTypeOpen, allNotify])

  // drawer animation
  const drawerNode = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (drawerNode.current === null) return

    if (isOpen) drawerNode.current.classList.add('slide')
    else drawerNode.current.classList.remove('slide')
  }, [isOpen])

  // type card animation
  const typesElement = useRef<Record<NotifyTypes, HTMLDivElement | null>>(getTypeData(null))

  const [topMap, setTopMap] = useState<Record<NotifyTypes, { [props: string]: string }>>(getTypeData({}))
  useEffect(() => {
    setTopMap(() => {
      const result: any = {}

      sortTypeList.forEach(type => {
        if (result[type] === undefined) result[type] = {}
        // 收合按鈕高度
        let typeHeight = 24 + 8
        const length = typesElement.current[type]?.childElementCount || 0

        if (!isTypeOpen[type] && allNotify[type].length === 0) return

        if (isTypeOpen[type]) {
          // 設定各卡片高度
          for (let i = length - 1; i >= 0; --i) {
            const item = typesElement.current[type]?.childNodes[i] as HTMLDivElement
            if (item.tagName === 'DIV') {
              result[type][i] = `translateY(${typeHeight}px)`
              typeHeight += item.offsetHeight + 10
            }
          }
          // 展開種類高度
          typesElement.current[type]?.style.setProperty('height', typeHeight + 16 + 'px')
        } else {
          // 設定各卡片高度
          for (let i = length - 1; i >= 0; --i) {
            const item = typesElement.current[type]?.childNodes[i] as HTMLDivElement
            if (item.tagName === 'DIV') result[type][i] = `translateY(0px) scaleX(${1 - (length - i - 1) * 0.05})`
          }
          // 展開種類高度
          typesElement.current[type]?.style.setProperty('height', '')
        }
      })

      return result
    })
  }, [currMapList])

  // 通知測試
  useNotifyTest(queue)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>

        <NotifyHint
          isOpen={isOpen}
          list={newNotifyList}
          handleOpen={handleOpen}
        />

        <Styled.Container ref={drawerNode}>
          <Styled.Drawer>
            <Styled.Header>
              <Styled.CloseButton color="grey200" src={ArrowRightIcon} onClick={handleClose} />
              <Styled.ClearButton onClick={handleClearAll}>
                <DeleteIcon />
                <SFText level={1}>{t('clearAll')}</SFText>
              </Styled.ClearButton>

              <Styled.BackTop ref={backTopNode} onClick={handleToTop}>
                <Styled.BackTopIcon src={ArrowRightIcon} color="white" />
                <SFText level={2} fontWeight="600" color="white">{t('amountNewNotify', { amount: newNotifyList.length })}</SFText>
              </Styled.BackTop>
            </Styled.Header>

            <Styled.Content ref={contentNode} onScroll={handleScroll}>
              { !haveMessage ?
                <Styled.NoMessageStatus>
                  <Styled.NoMessageImage mt="88px" src={EmptyMessageIcon} />
                  <SFText display="block" color="grey200" fontWeight="600">No message</SFText>
                </Styled.NoMessageStatus> : null
              }

              {/* 新訊息 */}
              <NewNotify
                list={newNotifyList}
                setList={setNewNotifyList}
              />

              {/* 分類訊息 */}
              { sortTypeList.map(type => (
                <Styled.TypeWrap
                  ref={(element) => typesElement.current[type] = element}
                  key={type}
                  onClick={(event) => handleTypeOpen(event, type, true)}
                  show={isTypeOpen[type]}
                  length={currMapList[type].length}
                >
                  <Styled.Collapse
                    show={isTypeOpen[type] ? 1 : 0}
                    onClick={(event) => handleTypeOpen(event, type, false)}
                    circle
                  >
                    <SFText level={1} fontWeight="400" color="grey200">{t('collapse')}</SFText>
                    <Styled.CollapseIcon src={ExpandIcon} color="grey200" />
                  </Styled.Collapse>

                  { currMapList[type].map((item, index) => (
                    <Styled.NotifyCard
                      key={item.id}
                      index={index}
                      length={currMapList[type].length}
                      isTypeOpen={isTypeOpen[type]}
                      id={item.id}
                      transform={topMap[type][index + 1]}
                      onClick={() => handleLink(type, item.link)}
                    >
                      <Styled.Title>
                        { item.icon ? <item.icon /> : null }
                        <SFText ml="12px" level={2} color="grey50" fontWeight="700">{ t(item.title, item.data) }</SFText>
                        <Styled.CloseIcon onClick={(event) => handleDelete(event, type, index, item.id)} />
                      </Styled.Title>
                      <Styled.Text width={1} level={1} color="grey40">
                        { isTypeOpen[type] ? <Styled.Pre>{t(item.message, { ...item.data, brokerName })}</Styled.Pre> : <SFAutoEllipsis value={t(item.message, { ...item.data, brokerName })} /> }
                      </Styled.Text>
                      <SFText width={1} level={1} color="grey30">{ !isTypeOpen[type] && allNotify[type].length > 1 ? t('stillAmountNotify', { amount: allNotify[type].length - 1 }) : timeFormat({ value: item.time, to: 'YYYY-MM-DD, HH:mm:ss'})}</SFText>
                    </Styled.NotifyCard>
                  ))}
                </Styled.TypeWrap>
              ))}
            </Styled.Content>
          </Styled.Drawer>
        </Styled.Container>
      </div>
    </ClickAwayListener>
  )
}

interface NotifyHintProps {
  isOpen: boolean
  list: any[]
  handleOpen: () => void
}

const NotifyHint = memo(({ isOpen, list, handleOpen }: NotifyHintProps) => (
  <Styled.NotifyWrapper>
    <Notify
      isDrawerOpen={isOpen}
      list={list}
      handleOpen={handleOpen}
    />
    <Message />
  </Styled.NotifyWrapper>
))

export default NotifySystem
