import { ThemeProvider } from 'styled-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setUserInfo, setAuth, setTheme, updateCompetitionWallet, clearCompetitionWallet, updateCreditDeposit, clearCreditDeposit, updateBrokerSubscriptions, clearBrokerSubscriptions, updateCompanyCustomizedMenu, setDarkMode } from '@/store/actions'
import { RootState } from '@/store'
import { updateUserWallet, clearWallet, updateConfigSymbol, clearConfigSymbol, updateStrategy, clearStrategy, updateRobot, clearRobot, updateCompetition, clearCompetition, updateCompanyInfo } from '@/store/actions'
import { webSocket } from '@/services/api-helper/services/webSocket'
import { GetUserService } from '@/services/api-helper/user'
import { LoadingInto, SFErrorBoundary } from '@otsofintech/sofinx-ui'
import { deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import Router from '@/routes'
import { theme, lightPalette, darkPalette, lightShadow, darkShadow } from '@otsofintech/sofinx-ui/lib/style'
import { UserAuth } from '@/utils/enum'
import { GlobalStyle, middleware } from './middleware'
import { isSofinqDomain } from './utils/helper'

middleware()

const App = () => {
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  const brokerName = useSelector((state: RootState) => state.companyInfo.name)
  useEffect(() => { document.title = brokerName }, [brokerName])

  const dispatch = useDispatch()
  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)

  // get user info
  useEffect(() => {
    getInitData()

    async function getInitData () {
      const result = await GetUserService()
      if (!result.isError) {
        changeLanguage(result.value.language)
        dispatch(setAuth(UserAuth.MEMBER))
        dispatch(setUserInfo(result.value))
      }
      setIsLoading(false)
    }

    // set language
    function changeLanguage (language: string) {
      if (language === i18n.language) return
      localStorage?.setItem('language', language)
      i18n.changeLanguage(language)
    }
  }, [])

  // Handle Global Data
  useEffect(() => {
    dispatch(updateCompanyInfo())
    dispatch(updateCompanyCustomizedMenu())
  }, [])

  useEffect(() => {
    if (isLogin) getData()
    else clearData()

    function clearData () {
      dispatch(clearWallet())
      dispatch(clearConfigSymbol())
      dispatch(clearStrategy())
      dispatch(clearRobot())
      dispatch(clearCompetition())
      dispatch(clearCompetitionWallet())
      dispatch(clearCreditDeposit())
      dispatch(clearBrokerSubscriptions())
    }

    function getData () {
      dispatch(updateStrategy())
      dispatch(updateUserWallet())
      dispatch(updateConfigSymbol())
      dispatch(updateRobot())
      dispatch(updateCompetition())
      dispatch(updateCompetitionWallet())
      dispatch(updateCreditDeposit())
      dispatch(updateBrokerSubscriptions())
    }
  }, [isLogin])

  // websocket
  useEffect(() => webSocket.create(), [])
  useEffect(() => { isLogin ? webSocket.getAuth() : webSocket.clearAuth() }, [isLogin])

  // theme mode
  const { chartColor, darkMode } = useSelector((state: RootState) => state.setting)
  const styleTheme = useSelector((state: RootState) => state.theme)
  useEffect(() => {
    const newTheme = deepCopy(theme)
    const palette = darkMode ? { ...darkPalette } : { ...lightPalette } as any
    palette.up = chartColor === 'greenUp' ? newTheme.palette.primary : newTheme.palette.error2
    palette.down = chartColor === 'greenUp' ? newTheme.palette.error2 : newTheme.palette.primary
    newTheme.shadow = darkMode ? { ...darkShadow } : { ...lightShadow }
    newTheme.palette = palette
    dispatch(setTheme(newTheme))
  }, [chartColor, darkMode])

  useEffect(()=>{
    if(isSofinqDomain()) dispatch(setDarkMode(true))
  },[])

  return (
    <ThemeProvider theme={styleTheme}>
      <SFErrorBoundary>
        {/* tutorial 暫時放這，等想到更適合的位子再移動 */}
        <div
          id="tutorial-component-root"
          style={{ position: 'absolute', zIndex: 101, pointerEvents: 'none' }}
        />
        <GlobalStyle />
        { isLoading ? <LoadingInto /> : <Router /> }
      </SFErrorBoundary>
    </ThemeProvider>
  )
}

export default App
