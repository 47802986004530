import styled from 'styled-components'
import { SFSVG } from '@otsofintech/sofinx-ui'

export const Container = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  position: absolute;
  top: 52px;
  right: 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${ props => props.theme.shadow[600] };
`

export const SettingButton = styled(SFSVG)`
  width: 24px;
  height: 24px;
  &:hover {
    fill: ${ props => props.theme.palette.primary };
  }
`

export const MenuContent = styled.div`
  padding: 16px 0;
  min-width: 248px;
  width: max-content;
  background-color: ${ props => props.theme.palette.blank };
`

export const ChartColorContent = styled.div`
  margin: 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const SettingItem = styled.div`
  margin: 8px 8px 0 24px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`
