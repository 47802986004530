import { Palette, theme } from '@otsofintech/sofinx-ui/lib/style'
import { HTMLAttributes } from 'react'

interface ChartColorIconProps extends HTMLAttributes<HTMLOrSVGElement> {
  up: Palette
  down: Palette
  active?: boolean
}

const ChartColorIcon = ({
  up,
  down,
  active = false,
  ...props
}: ChartColorIconProps) => {
  return (
    <svg width="57" height="48" viewBox="0 0 57 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1.47754" y="0.75" width="54.7723" height="46.2045" rx="2.55159" fill="transparent"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.1964 16.5898C22.2847 16.5898 21.5457 17.3289 21.5457 18.2406V27.1441C21.5457 28.0558 22.2847 28.7949 23.1964 28.7949H25.7129C26.6246 28.7949 27.3637 28.0558 27.3637 27.1441V18.2406C27.3637 17.3289 26.6246 16.5898 25.7129 16.5898H23.1964Z" fill={theme.palette[up]}/>
      <path d="M23.7275 13.7272C23.7275 13.3256 24.0531 13 24.4548 13C24.8564 13 25.182 13.3256 25.182 13.7272V16.5804C25.182 16.9821 24.8564 17.3077 24.4548 17.3077C24.0531 17.3077 23.7275 16.9821 23.7275 16.5804V13.7272Z" fill={theme.palette[up]}/>
      <path d="M23.7278 28.8042C23.7278 28.4025 24.0534 28.0769 24.455 28.0769C24.8567 28.0769 25.1823 28.4025 25.1823 28.8042V31.6573C25.1823 32.059 24.8567 32.3846 24.455 32.3846C24.0534 32.3846 23.7278 32.059 23.7278 31.6573V28.8042Z" fill={theme.palette[up]}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.0146 23.5897C31.1029 23.5897 30.3638 24.3288 30.3638 25.2405V36.9389C30.3638 37.8506 31.1029 38.5897 32.0146 38.5897H34.531C35.4427 38.5897 36.1818 37.8506 36.1818 36.9389V25.2405C36.1818 24.3288 35.4427 23.5897 34.531 23.5897H32.0146Z" fill={theme.palette[down]}/>
      <path d="M32.5457 20.7272C32.5457 20.3256 32.8713 20 33.2729 20C33.6746 20 34.0002 20.3256 34.0002 20.7272V23.5804C34.0002 23.9821 33.6746 24.3077 33.2729 24.3077C32.8713 24.3077 32.5457 23.9821 32.5457 23.5804V20.7272Z" fill={theme.palette[down]}/>
      <path d="M32.5459 38.1241C32.5459 37.7225 32.8715 37.3969 33.2731 37.3969C33.6748 37.3969 34.0004 37.7225 34.0004 38.1241V40.9773C34.0004 41.3789 33.6748 41.7045 33.2731 41.7045C32.8715 41.7045 32.5459 41.3789 32.5459 40.9773V38.1241Z" fill={theme.palette[down]}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7275 22.6667C12.7275 21.9303 13.3787 21.3334 14.182 21.3334H17.091C17.8943 21.3334 18.5455 21.9303 18.5455 22.6667V31.3334C18.5455 32.0698 17.8943 32.6667 17.091 32.6667H14.182C13.3787 32.6667 12.7275 32.0698 12.7275 31.3334V22.6667Z" fill={theme.palette[down]}/>
      <path d="M14.9094 18.6667C14.9094 18.2985 15.235 18 15.6367 18C16.0383 18 16.3639 18.2985 16.3639 18.6667V21.3333C16.3639 21.7015 16.0383 22 15.6367 22C15.235 22 14.9094 21.7015 14.9094 21.3333V18.6667Z" fill={theme.palette[down]}/>
      <path d="M14.9097 32.6667C14.9097 32.2985 15.2353 32 15.6369 32C16.0386 32 16.3642 32.2985 16.3642 32.6667V35.3333C16.3642 35.7015 16.0386 36 15.6369 36C15.2353 36 14.9097 35.7015 14.9097 35.3333V32.6667Z" fill={theme.palette[down]}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M39.1819 11.0724C39.1819 10.0978 39.8331 9.30768 40.6364 9.30768H43.5454C44.3487 9.30768 44.9999 10.0978 44.9999 11.0724V26.9531C44.9999 27.9277 44.3487 28.7178 43.5454 28.7178H40.6364C39.8331 28.7178 39.1819 27.9277 39.1819 26.9531L39.1819 11.0724Z" fill={theme.palette[up]}/>
      <path d="M41.364 6.71795C41.364 6.32144 41.6896 6 42.0913 6C42.4929 6 42.8185 6.32144 42.8185 6.71795V9.58974C42.8185 9.98626 42.4929 10.3077 42.0913 10.3077C41.6896 10.3077 41.364 9.98626 41.364 9.58974V6.71795Z" fill={theme.palette[up]}/>
      <path d="M41.364 28.4358C41.364 28.0393 41.6896 27.7178 42.0913 27.7178C42.4929 27.7178 42.8185 28.0393 42.8185 28.4358V31.3076C42.8185 31.7041 42.4929 32.0255 42.0913 32.0255C41.6896 32.0255 41.364 31.7041 41.364 31.3076V28.4358Z" fill={theme.palette[up]}/>
      { active ? <rect x="1.47754" y="0.75" width="54.7723" height="46.2045" rx="2.55159" stroke="#D9E4E6" strokeWidth="1.5"/> : null }
    </svg>
  )
}

export default ChartColorIcon
