import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { clearAuth, clearUserInfo } from '@/store/actions'
import { RootState } from '@/store'
import { useHistory } from 'react-router-dom'
import { loader } from '@/hooks/useLoading'
import { SFText, SFAutoEllipsis, SFSVG, SFIconImage } from '@otsofintech/sofinx-ui'
import { LogoutService } from '@/services/api-helper/auth'
import DefaultUser from '@/assets/images/freeze-color/default-user.svg'
import AccountIcon from '@/assets/images/user-circle.svg'
import AssetsIcon from '@/assets/images/assets.svg'
import ArrowIcon from '@/assets/images/arrow-left.svg'
// import PersonalIcon from '@/assets/images/personal.svg'
// import SecurityIcon from '@/assets/images/security.svg'
// import PaymentIcon from '@/assets/images/payment.svg'
import LogoutIcon from '@/assets/images/logout.svg'
import ReferralIcon from '@/assets/images/referral.svg'
import * as Styled from './index.style'
import { isSofinqDomain } from '@/utils/helper'

const navList = [
  { name: 'user-assets', icon: AssetsIcon, label: 'userAssets' },
  // { name: 'personal', icon: PersonalIcon, label: 'personal' },
  // { name: 'security', icon: SecurityIcon, label: 'security' },
  // { name: 'payment', icon: PaymentIcon, label: 'payment' },
  { name: 'referral', icon: ReferralIcon, label: 'referral' },
]

const UserMenu = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const userInfo = useSelector((state: RootState) => state.userInfo)

  const dispatch = useDispatch()
  const handleLogout = async () => {
    loader.load()
    const result = await LogoutService()
    if (result.value) {
      dispatch(clearUserInfo())
      dispatch(clearAuth())
    }
    loader.unload()
  }

  const menuNode = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    menuNode.current?.classList.add('close')
    setTimeout(() => { menuNode.current?.classList.remove('close') }, 300)
  }

  return (
    <Styled.Container>
      {!userInfo.headshot ?  <Styled.AccountSVG src={AccountIcon} color='grey50' width='24px' height='24px' pointer/>:<Styled.CircleDiv><Styled.Circle className="icon" src={userInfo.headshot} /></Styled.CircleDiv>}
      <Styled.MenuContent ref={menuNode} className="menu">
        <Styled.UserHeader onClick={() => history.push('/setting')}>
          <SFIconImage src={userInfo.headshot} defaultSrc={DefaultUser} width="32px" height="32px" style={{ marginRight: '16px', borderRadius: '50%' }} />
          <div>
            <SFText level={2} fontWeight="600" color="grey50" alignItems="center">
              <SFAutoEllipsis value={userInfo.username} style={{ maxWidth: '160px' }} tooltip />
              <SFSVG src={ArrowIcon} color="grey30" style={{ transform: 'rotate(180deg)' }} width="18px" height="18px" />
            </SFText>
            <SFText mt="6px" display="block" height="16px" level={1} color="grey30">
              <SFAutoEllipsis value={`UID: ${userInfo.id}`} width="184px" tooltip />
            </SFText>
          </div>
        </Styled.UserHeader>

        {!isSofinqDomain() && <Styled.NavList>
          { navList.map(item => (
            <Styled.NavItem
              onClick={handleClick}
              key={item.name}
              to={`/${item.name}`}
              activeClassName="active-menu"
            >
              <Styled.IconSVG src={item.icon} color="grey200" />
              <SFText width={1} level={2}>{ t(item.label) }</SFText>
            </Styled.NavItem>
          ))}
        </Styled.NavList>}

        <Styled.Logout>
          <Styled.IconSVG src={LogoutIcon} color="grey200" />
          <SFText width={1} level={2} color="grey40" onClick={handleLogout}>{t('logout')}</SFText>
        </Styled.Logout>
      </Styled.MenuContent>
    </Styled.Container>
  )
}

export default UserMenu
